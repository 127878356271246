import TYPES from '@/types';

import Vue from 'vue';

import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetInvestorGoalMovementsQuery
  from '@/modules/flagship/investor-goal/movements/application/queries/get-investor-goal-movements-query';

// Domain
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import {
  GetInvestorGoalMovementsQueryDto,
} from '@/modules/flagship/investor-goal/movements/domain/dtos/get-investor-goal-movements-query-dto';
import {
  InvestorGoalMovementEntity,
} from '@/modules/flagship/investor-goal/movements/domain/entities/investor-goal-movement-entity';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

type MovementFormated = {
  name: string;
  sign: string;
  text_color: string;
  icon: string;
  icon_color: string;
  movement_date: string;
  movement_amount: string;
};

export default class InvestorGoalMovementsViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.GET_INVESTOR_GOAL_MOVEMENTS_QUERY)
  readonly get_investor_goal_movements_query!: GetInvestorGoalMovementsQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.goals-dashboard.linked-goals.movements';

  readonly view: Vue;

  is_loading = false;

  styles = {
    color_card: '',
    color_title: 'primary--text',
    color_divider: '',
    color_icon: 'sky-blue',
    color_btn: 'white--text',
  }

  icon_path = '';

  icon_max_width = 30;

  movement_option_selected = '';

  movements_options = [
    {
      movement_type_code: '',
      value: 'Todos los movimientos',
    },
    {
      movement_type_code: 'deposit',
      value: 'Depósito',
    },
    {
      movement_type_code: 'withdrawal',
      value: 'Retiro',
    },
  ];

  dates_selected = ['2022-02-15', '2022-03-25'];

  movements: MovementFormated[] = [];

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  changeStyles = () => {
    if (this.manage_tracking_investor_goal_state.strategy.name.includes('Moderado')) {
      this.styles.color_card = 'primary';
      this.styles.color_title = 'white--text';
      this.styles.color_divider = 'white';
      this.styles.color_icon = 'white';
      this.styles.color_btn = 'accent--text';
    }
  }

  changeDates = async (range_of_dates: Array<string>) => {
    this.dates_selected = range_of_dates;
    await this.loadMovements();
  }

  loadIcon = () => {
    this.icon_path = getGoalIconPath(
      this.manage_tracking_investor_goal_state.icon.file_id,
      this.manage_tracking_investor_goal_state.icon.name,
      this.manage_tracking_investor_goal_state.icon.updated_at.toString(),
    );
  }

  /**
  * Calculate a date range of 90 days ending on the given current date.
  * @param currentDate - The current date.
  * @returns A tuple containing the start date and end date as strings in YYYY-MM-DD format.
  */
  calculateDateRange = (currentDate: Date): [string, string] => {
    const daysOffset = 90;

    // Calculate the start and end dates
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - daysOffset);

    const endDate = new Date(currentDate);

    // Format the dates as YYYY-MM-DD
    const formatToISODate = (date: Date) => date.toISOString().split('T')[0];
    return [formatToISODate(startDate), formatToISODate(endDate)];
  };

  formatMovements = (movements: InvestorGoalMovementEntity[]) => {
    this.movements = movements.map((movement) => {
      const formatted_movement: MovementFormated = {
        name: 'Depósito',
        sign: '+',
        text_color: this.styles.color_title,
        icon: 'mdi-plus',
        icon_color: 'secondary',
        movement_date: this.date_formatter.formatDate(movement.movement_date, 'DD MMM YYYY'),
        movement_amount: `${currencyFormat(movement.movement_amount)} MXN`,
      };

      if (movement.movement_type_code === 'withdrawal') {
        formatted_movement.sign = '-';
        formatted_movement.name = 'Retiro';
        formatted_movement.text_color = 'error';
        formatted_movement.icon = 'mdi-minus';
        formatted_movement.icon_color = 'error';
      }
      return formatted_movement;
    });
  }

  loadMovements = async () => {
    try {
      this.is_loading = true;
      this.movements.length = 0;
      const query: GetInvestorGoalMovementsQueryDto = {
        investor_goal_id: this.manage_tracking_investor_goal_state.investor_goal_id,
        from_date: this.dates_selected[0],
        to_date: this.dates_selected[1],
        movement_type_code: this.movement_option_selected,
      };
      const movements_without_format = await this
        .get_investor_goal_movements_query.execute(query);
      this.formatMovements(movements_without_format);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_movements'));
    } finally {
      this.is_loading = false;
    }
  }

  initialize = async () => {
    this.changeStyles();
    this.dates_selected = this.calculateDateRange(new Date());
    this.loadIcon();
  }
}
